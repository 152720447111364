export const FETCH_SIX36_MANAGEMENT_REPORTS = "FETCH_SIX36_MANAGEMENT_REPORTS";
export const LIST_SIX36_MANAGEMENT_REPORTS = "LIST_SIX36_MANAGEMENT_REPORTS";


export const FETCH_DRAW_HISTORY = "FETCH_DRAW_HISTORY";
export const LIST_DRAW_HISTORY = "LIST_DRAW_HISTORY";
export const LIST_DRAW_LOTTO99_HISTORY = "LIST_DRAW_LOTTO99_HISTORY";

export const EXPORT_SIX36_MANAGEMENT_REPORTS = "EXPORT_SIX36_MANAGEMENT_REPORTS";
export const EXPORTED_SIX36_MANAGEMENT_REPORTS = "EXPORTED_SIX36_MANAGEMENT_REPORTS";

export const FETCH_LOTTO5_MANAGEMENT_REPORTS = "FETCH_LOTTO5_MANAGEMENT_REPORTS";
export const LIST_LOTTO5_MANAGEMENT_REPORTS = "LIST_LOTTO5_MANAGEMENT_REPORTS";

export const FETCH_LOTTO12_MANAGEMENT_REPORTS = "FETCH_LOTTO5_MANAGEMENT_REPORTS";
export const LIST_LOTTO12_MANAGEMENT_REPORTS = "LIST_LOTTO5_MANAGEMENT_REPORTS";

export const EXPORT_LOTTO5_MANAGEMENT_REPORTS = "EXPORT_LOTTO5_MANAGEMENT_REPORTS";
export const EXPORTED_LOTTO5_MANAGEMENT_REPORTS = "EXPORTED_LOTTO5_MANAGEMENT_REPORTS";

export const EXPORT_LOTTO12_MANAGEMENT_REPORTS = "EXPORT_LOTTO5_MANAGEMENT_REPORTS";
export const EXPORTED_LOTTO12_MANAGEMENT_REPORTS = "EXPORTED_LOTTO5_MANAGEMENT_REPORTS";

export const FETCH_WIN_LOSE_HISTORY = "FETCH_WIN_LOSE_HISTORY";
export const LIST_WIN_LOSE_HISTORY = "LIST_WIN_LOSE_HISTORY";
export const CLEAR_WIN_LOSE_HISTORY = "CLEAR_WIN_LOSE_HISTORY";
export const ERROR_LOSE_HISTORY = "ERROR_LOSE_HISTORY";

export const FETCH_STATEMENT_BALANCE = "FETCH_STATEMENT_BALANCE";
export const LIST_STATEMENT_BALANCE = "LIST_STATEMENT_BALANCE";

export const EXPORT_STATEMENT_BALANCE = "EXPORT_STATEMENT_BALANCE";
export const EXPORTED_STATEMENT_BALANCE = "EXPORTED_STATEMENT_BALANCE";

export const FETCH_SALE_REPORTS = "FETCH_SALE_REPORTS";
export const LIST_SALE_REPORTS = "LIST_SALE_REPORTS";

export const FETCH_SALE_REPORTS_BY_HOUR = "FETCH_SALE_REPORTS_BY_HOUR";
export const LIST_SALE_REPORTS_BY_HOUR = "LIST_SALE_REPORTS_BY_HOUR";


export const FETCH_STATISTIC_REPORTS = "FETCH_STATISTIC_REPORTS";
export const LIST_STATISTIC_REPORTS = "LIST_STATISTIC_REPORTS";
export const CLEAR_STATISTIC_REPORTS = "CLEAR_STATISTIC_REPORTS";


export const LIST_STATISTIC_REPORTS_FILTER = "LIST_STATISTIC_REPORTS_FILTER";

export const EXPORT_SALE_REPORTS = "EXPORT_SALE_REPORTS";
export const EXPORTED_SALE_REPORTS = "EXPORTED_SALE_REPORTS";


export const FETCH_UNDER_SALE_REPORTS = "FETCH_UNDER_SALE_REPORTS";
export const LIST_UNDER_SALE_REPORTS = "LIST_UNDER_SALE_REPORTS";
export const EXPORT_UNDER_SALE_REPORTS = "EXPORT_UNDER_SALE_REPORTS";
export const EXPORTED_UNDER_SALE_REPORTS = "EXPORTED_UNDER_SALE_REPORTS";

export const FETCH_ANALYZE = "FETCH_ANALYZE";
export const LIST_ANALYZE = "LIST_ANALYZE";

export const FETCH_LOTTO5D_MANAGEMENT = "FETCH_LOTTO5D_MANAGEMENT";
export const LIST_LOTTO5D_MANAGEMENT = "LIST_LOTTO5D_MANAGEMENT";

export const FETCH_LOTTO99 = "FETCH_LOTTO99";
export const LIST_LOTTO99 = "LIST_LOTTO99";

export const FETCH_DRAW_LOTTO12v2_HISTORY = "FETCH_DRAW_LOTTO12v2_HISTORY";
export const LIST_DRAW_LOTTO12v2_HISTORY = "LIST_DRAW_LOTTO12v2_HISTORY";
