
const init = {
  list: [
    {
      code: "ALL",
      name: "ALL"
    },
    {
      code: "LOTTO5",
      name: "LOTTO5"
    },
    {
      code: "LOTTO6",
      name: "6/36"
    },
    {
      code: "LOTTO12",
      name: "LOTTO12"
    },
    {
      code: "LOTTO90",
      name: "LOTTO90"
    },
    {
      code: "LOTTO5D",
      name: "LOTTO5D"
    },
    {
      code: "YUKI",
      name: "1/12"
    },
    {
      code: "LOTTO99",
      name: "1/99"
    },
  ]
};

export default (state = init, {type, payload}) => {
  return state;
}
