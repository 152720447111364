import {
  EXPORT_LOTTO12_MANAGEMENT_REPORTS,
  EXPORTED_LOTTO12_MANAGEMENT_REPORTS,
  FETCH_LOTTO12_MANAGEMENT_REPORTS,
  LIST_LOTTO12_MANAGEMENT_REPORTS
} from "./types";

const init = {
  isLoading: false,
  filter: {},
  list: [],
  page: 1,
  size: 10,
  totals: 0,
  lastUpdated: null
};

export default (state = init, {type, payload}) => {
  switch (type) {
    case EXPORT_LOTTO12_MANAGEMENT_REPORTS:
    case FETCH_LOTTO12_MANAGEMENT_REPORTS:
      return {
        ...state,
        isLoading: payload.isLoading,
        filter: payload.filter
      };
    case LIST_LOTTO12_MANAGEMENT_REPORTS:
      return {
        ...state,
        lastUpdated: new Date(),
        isLoading: false,
        list: payload.data,
        ...payload.paging
      };
    case EXPORTED_LOTTO12_MANAGEMENT_REPORTS:
      return {
        ...state,
        list: payload.data,
        ...payload.paging
      };
    default:
      return state;
  }
}