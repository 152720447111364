import {
    FETCH_LOTTO5D_MANAGEMENT, LIST_LOTTO5D_MANAGEMENT
} from "./types";

const init = {
    isLoading: false,
    list: [],
    page: 1,
    size: 10,
    totals: 0
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_LOTTO5D_MANAGEMENT:
            return {
                ...state,
                ...init,
                isLoading: true
            };
        case LIST_LOTTO5D_MANAGEMENT:
            return {
                ...state,
                isLoading: false,
                list: payload.data,
                ...payload.paging
            };
        default:
            return state;
    }
}