import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form';
import config from './templateConfig/';
import Navigation from './navigation/';
import icons from "./icons/";

import dashboardReducer from "./dashboard";
import referralReducer from "./referral";
import authReducer from "./auth";
import financeReducer from "./finance";
import administrationReducer from "./administration";
import reportsReducer from "./reports";
import analyzeReportReducer from "./analyze-report"

const rootReducer = combineReducers({
  form: formReducer,
  dashboard: dashboardReducer,
  auth: authReducer,
  referral: referralReducer,
  administration: administrationReducer,
  reports: reportsReducer,
  analyzeReport: analyzeReportReducer,
  finance: financeReducer,
  config,
  Navigation,
  icons
});

export default rootReducer;