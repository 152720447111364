export const FETCH_ACTIVITIES = "FETCH_ACTIVITIES";
export const LIST_ACTIVITIES = "LIST_ACTIVITIES";

export const FETCH_EMPLOYEES = "FETCH_EMPLOYEES";
export const LIST_EMPLOYEES = "LIST_EMPLOYEES";
export const FETCH_EMPLOYEE = "FETCH_EMPLOYEE";
export const SHOW_EMPLOYEE = "SHOW_EMPLOYEE";
export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const MODIFIED_EMPLOYEE = "MODIFIED_EMPLOYEE";
export const REMOVE_EMPLOYEE = "REMOVE_EMPLOYEE";

export const FETCH_ROLES = "FETCH_ROLES";
export const FILTER_PERMISSIONS = "FILTER_PERMISSIONS";
export const LIST_ROLES = "LIST_ROLES";
export const FETCH_ROLE = "LIST_ROLE";
export const GET_ROLE = "GET_ROLE";
export const CREATE_ROLE = "CREATE_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";

export const FETCH_PERMISSIONS = "FETCH_PERMISSIONS";
export const LIST_PERMISSIONS = "LIST_PERMISSIONS";

export const FETCH_SETTINGS = "FETCH_SETTINGS";
export const LIST_SETTINGS = "LIST_SETTINGS";
export const MODIFY_SETTINGS = "MODIFY_SETTINGS";

export const FETCH_MANAGE_RESULT = "FETCH_MANAGE_RESULT";
export const LIST_MANAGE_RESULT = "LIST_MANAGE_RESULT";
export const MODIFY_MANAGE_RESULT = "MODIFY_MANAGE_RESULT";
export const FETCH_PROBABILITY_TYPE = "FETCH_PROBABILITY_TYPE";
export const LIST_PROBABILITY_TYPE = "LIST_PROBABILITY_TYPE";

export const FETCH_MANAGE_PROBABILITY = "FETCH_MANAGE_PROBABILITY";
export const LIST_MANAGE_PROBABILITY = "LIST_MANAGE_PROBABILITY";
export const MODIFY_MANAGE_PROBABILITY = "MODIFY_MANAGE_PROBABILITY";
export const CLEAR_MANAGE_PROBABILITY = "CLEAR_MANAGE_PROBABILITY";

export const FETCH_OTHER = "FETCH_OTHER";
export const LIST_OTHER = "LIST_OTHER";
export const MODIFY_OTHER = "MODIFY_OTHER";
export const UPLOAD_APK = "UPLOAD_APK";
export const UPLOAD_ONLINE_APK = "UPLOAD_ONLINE_APK";
