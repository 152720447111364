import {GET_BALANCE_USER} from "./types";

const initState = {
  isLoading: true,
  data: {},
};

export default (state = initState, {payload, type}) => {
  switch (type) {
    case GET_BALANCE_USER:
      return {
        ...state,
        data: payload
      };
    default:
      return state;
  }
}