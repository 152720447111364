export const FETCH_ANALYZE_REPORT_REQUEST = "FETCH_ANALYZE_REPORT_REQUEST";
export const FETCH_ANALYZE_REPORT_SUCCESS = "FETCH_ANALYZE_REPORT_SUCCESS";
export const FETCH_ANALYZE_REPORT_FAILURE = "FETCH_ANALYZE_REPORT_FAILURE";

export const FETCH_ANALYZE_LOG_REQUEST = "FETCH_ANALYZE_LOG_REQUEST";
export const FETCH_ANALYZE_LOG_SUCCESS = "FETCH_ANALYZE_LOG_SUCCESS";
export const FETCH_ANALYZE_LOG_FAILURE = "FETCH_ANALYZE_LOG_FAILURE";
export const FETCH_LIMITED_ANALYZE_LOG_SUCCESS = 'FETCH_LIMITED_ANALYZE_LOG_SUCCESS';

export const FETCH_LOG_REQUEST = 'FETCH_LOG_REQUEST';
export const FETCH_LOG_SUCCESS = 'FETCH_LOG_SUCCESS';
export const FETCH_LOG_FAILURE = 'FETCH_LOG_FAILURE';