// import external modules
import { combineReducers } from "redux"
// import internal(own) modules
import profile from "./profile"
import balance from "./balance"

const authReducer = combineReducers({
  profile,
  balance
});

export default authReducer;
