import {FETCH_ACTIVITIES, LIST_ACTIVITIES} from "./types";

const init = {
    isLoading: true,
    list: [],
    page: 1,
    size: 10,
    totals: 0
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_ACTIVITIES:
            return {
                ...state,
                isLoading: true
            };
        case LIST_ACTIVITIES:
            return {
                ...state,
                isLoading: false,
                ...payload
            };
        default:
            return state;
    }
}