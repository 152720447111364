import {FETCH_MANAGE_RESULT, LIST_MANAGE_RESULT, LIST_PROBABILITY_TYPE} from "./types";

const init = {
  isLoading: true,
  list: [],
  probabilityTypes: [],
  page: 1,
  totals: 0,
  size: 10
};

export default (state = init, {type, payload}) => {
  switch (type) {
    case FETCH_MANAGE_RESULT:
      return {
        ...state,
        isLoading: true
      };
    case LIST_MANAGE_RESULT:
      return {
        ...state,
        isLoading: false,
        list: payload.data,
        ...payload.paging
      };
    case LIST_PROBABILITY_TYPE:
      return {
        ...state,
        isLoading: false,
        probabilityTypes: payload.data,
        ...payload.paging
      };
    default:
      return state;
  }
}