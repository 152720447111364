// import external modules
import { combineReducers } from "redux"
// import internal(own) modules
import dashboard from "./dashboard"

const authReducer = combineReducers({
  dashboard
});

export default authReducer;
