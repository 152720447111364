import {combineReducers} from "redux";
import history from "./history";
import winlose from "./winlose";
import statement from "./statement";
import sale from "./sale";
import saleByHour from "./saleByHour";
import six36 from "./six36";
import lotto5 from "./lotto5";
import lotto12 from "./lotto12";
import underSale from "./unerSale";
import lotto5_analyze from "./lotto5_analyze";
import six36_analyze from "./six36_analyze";
import lotto12_analyze from "./lotto12_analyze";
import lotto12v2_analyze from "./lotto12v2_analyze";
import lotto90_analyze from "./lotto90_analyze";
import lotto99_analyze from "./lotto99_analyze";
import lotto5d from "./lotto5d";
import lotto99 from "./lotto99";
import lotto12v2 from "./lotto12v2";
import statistic from "./statistic";

const reportsReducer = combineReducers({
    six36,
    lotto5,
    history,
    lotto12,
    winlose,
    statement,
    sale,
    saleByHour,
    underSale,
    lotto5_analyze,
    six36_analyze,
    lotto12_analyze,
    lotto12v2_analyze,
    lotto90_analyze,
    lotto99_analyze,
    lotto5d,
    lotto99,
    lotto12v2,
    statistic
});

export default reportsReducer;
