import {
    FETCH_ANALYZE_LOG_FAILURE,
    FETCH_ANALYZE_LOG_REQUEST,
    FETCH_ANALYZE_LOG_SUCCESS,
    FETCH_ANALYZE_REPORT_FAILURE,
    FETCH_ANALYZE_REPORT_REQUEST,
    FETCH_ANALYZE_REPORT_SUCCESS,
    FETCH_LIMITED_ANALYZE_LOG_SUCCESS,
    FETCH_LOG_FAILURE,
    FETCH_LOG_REQUEST,
    FETCH_LOG_SUCCESS
} from "./types";

const initialState = {
    list: [],
    analyzeLog: [],// For all items
    log: [],
    limitedAnalyzeLog: [], // For limited items (e.g., 3 items)
    isLoading: false,
    error: null,
    page: 1,
    size: 25,
    totals: 0
};

export default (state = initialState, {type, payload}) => {

    switch (type) {


        case FETCH_ANALYZE_REPORT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case FETCH_ANALYZE_REPORT_SUCCESS:
            return {
                ...state,
                list: payload.data,
                isLoading: false
            };
        case FETCH_ANALYZE_REPORT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            };


        case FETCH_ANALYZE_LOG_REQUEST:
            return {
                ...state,
                isLoading: true
            }

        case FETCH_ANALYZE_LOG_SUCCESS:
            return {
                ...state,
                analyzeLog: payload.data,
                isLoading: false
            }

        case FETCH_ANALYZE_LOG_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            }

        case FETCH_LIMITED_ANALYZE_LOG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                limitedAnalyzeLog: payload.data
            };


        case FETCH_LOG_REQUEST:
            return {
                ...state,
                isLoading: true,

            }

        case FETCH_LOG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                log: payload.data,
                ...payload.paging
            }

        case FETCH_LOG_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            }

        default:
            return state;
    }
}