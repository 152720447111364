import {
    CLEAR_MANAGE_PROBABILITY,
    FETCH_MANAGE_PROBABILITY,
    LIST_MANAGE_PROBABILITY,
    LIST_PROBABILITY_TYPE
} from "./types";

const init = {
    isLoading: false,
    data: {},
    filter: {},
    probabilityTypes: [],
    page: 1,
    totals: 0,
    size: 10,
    manageResult: []
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_MANAGE_PROBABILITY:
            return {
                ...state,
                filter: payload,
                isLoading: true
            };
        case LIST_MANAGE_PROBABILITY:
            return {
                ...state,
                isLoading: false,
                data: payload.data,
                ...payload.paging
            };
        case LIST_PROBABILITY_TYPE:
            return {
                ...state,
                isLoading: false,
                probabilityTypes: payload.data,
                ...payload.paging
            };
        case CLEAR_MANAGE_PROBABILITY:
            return {
                ...init
            };
        default:
            return state;
    }
}
