import {FETCH_SALE_REPORTS_BY_HOUR, LIST_SALE_REPORTS_BY_HOUR} from "./types";

const init = {
    filter: {},
    isLoading: false,
    data: {
        header: [],
        item: []
    },
    page: 1,
    size: 10,
    totals: 0
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_SALE_REPORTS_BY_HOUR:
            return {
                ...state,
                filter: payload,
                isLoading: true
            };
        case LIST_SALE_REPORTS_BY_HOUR:
            return {
                ...state,
                isLoading: false,
                data: payload.data,
                ...payload.paging
            };
        default:
            return state;
    }
}