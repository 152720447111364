import {CLEAR_WIN_LOSE_HISTORY, ERROR_LOSE_HISTORY, FETCH_WIN_LOSE_HISTORY, LIST_WIN_LOSE_HISTORY} from "./types";

const init = {
    isLoading: false,
    filter: {},
    list: [],
    page: 1,
    size: 50,
    totals: 0
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_WIN_LOSE_HISTORY:
            return {
                ...state,
                isLoading: true,
                filter: payload
            };
        case LIST_WIN_LOSE_HISTORY:
            return {
                ...state,
                isLoading: false,
                list: payload.data,
                ...payload.paging
            };
        case ERROR_LOSE_HISTORY:
            return {
                ...state,
                isLoading: false,
            };
        case CLEAR_WIN_LOSE_HISTORY:
            return {
                ...init
            };
        default:
            return state;
    }
}