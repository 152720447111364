// import external modules
import { combineReducers } from "redux"
// import internal(own) modules
import activity from "./activity";
import employee from "./employee";
import role from "./role";
import setting from "./setting";
import manageResult from "./manageResult";
import game from "./game";
import status from "./status";
import other from "./other";
import manageProbability from "./manageProbability";

const administrationReducer = combineReducers({
  activity,
  employee,
  setting,
  manageResult,
  role,
  game,
  status,
  other,
  manageProbability
});

export default administrationReducer;
