import {
    CLEAR_STATISTIC_REPORTS,
    FETCH_STATISTIC_REPORTS,
    LIST_STATISTIC_REPORTS,
    LIST_STATISTIC_REPORTS_FILTER,
} from "./types";


const init = {
    filter: {},
    isLoading: false,
    filters: {},
    data: {
        items: [],
        betCodes: []
    },
    page: 1,
    size: 20,
    totals: 0
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_STATISTIC_REPORTS:
            return {
                ...state,
                isLoading: true,
                filter: payload,
            };
        case LIST_STATISTIC_REPORTS:
            return {
                ...state,
                isLoading: false,
                data: payload.data,
                ...payload.paging
            };
        case LIST_STATISTIC_REPORTS_FILTER:
            return {
                ...state,
                filters: payload.data,
            };

        case CLEAR_STATISTIC_REPORTS:
            return {
                ...init
            };
        default:
            return state;
    }
}