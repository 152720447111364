import {FETCH_DASHBOARDS, LIST_DASHBOARDS} from "./types";

const init = {
    isLoading: true,

    userLevels: [],
    todayYesterdays: [],
    compareAmounts: [],
    lotto5Ticket: [],
    lotto6Ticket: [],
    lotto12Ticket: [],

    page: 1,
    totals: 0,
    size: 10
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_DASHBOARDS:
            return {
                ...state,
                isLoading: true
            };
        case LIST_DASHBOARDS:
            return {
                ...state,
                ...payload.data,
                isLoading: false,
                ...payload.paging
            };
        default:
            return state;
    }
}