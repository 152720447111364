import {EXPORT_SALE_REPORTS, EXPORTED_SALE_REPORTS, FETCH_SALE_REPORTS, LIST_SALE_REPORTS} from "./types";

const init = {
  filter: {},
  isLoading: false,
  summary: {},
  list: [],
  page: 1,
  size: 10,
  totals: 0
};

export default (state = init, {type, payload}) => {
  switch (type) {
    case EXPORT_SALE_REPORTS:
      return {
        ...init,
        filter: payload,
        isLoading: true
      };
    case FETCH_SALE_REPORTS:
      return {
        ...state,
        filter: payload,
        isLoading: true
      };
    case LIST_SALE_REPORTS:
      return {
        ...state,
        isLoading: false,
        list: payload.data.items,
        summary: payload.data.summary,
        ...payload.paging
      };
    case EXPORTED_SALE_REPORTS:
      return {
        ...state,
        list: payload.data.items,
        summary: payload.data.summary,
        ...payload.paging
      };
    default:
      return state;
  }
}