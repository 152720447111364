import {
  FETCH_DRAW_LOTTO12v2_HISTORY,
  LIST_DRAW_LOTTO12v2_HISTORY
} from "./types";

const init = {
  isLoading: false,
  filter: {},
  list: [],
  page: 1,
  size: 10,
  totals: 0
};

export default (state = init, {type, payload}) => {
  switch (type) {
    case FETCH_DRAW_LOTTO12v2_HISTORY:
      return {
        ...state,
        filter: payload,
        isLoading: true
      };
    case LIST_DRAW_LOTTO12v2_HISTORY:
      return {
        ...state,
        isLoading: false,
        list: payload.data,
        ...payload.paging
      };
    default:
      return state;
  }
}
