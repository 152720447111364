import {DEPOSIT_WITHDRAW} from "./types";

const init = {};

export default (state = init, {type, payload}) => {
  switch (type) {
    case DEPOSIT_WITHDRAW:
      return {
        ...state,
        lastAction: payload
      };
    default:
      return state;
  }
}