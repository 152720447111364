import {
    FETCH_PERMISSIONS,
    FETCH_ROLE,
    FETCH_ROLES,
    FILTER_PERMISSIONS,
    GET_ROLE,
    LIST_PERMISSIONS,
    LIST_ROLES
} from "./types";
import _ from "lodash";

const init = {
    isLoading: true,
    type: "system",
    list: [],
    permission: {
        isLoading: true,
        list: []
    },
    active: {
        isLoading: false,
        role: {}
    },
    page: 0,
    totals: 0,
    size: 10
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_ROLES:
            return {
                ...state,
                isLoading: true
            };
        case LIST_ROLES:
            return {
                ...state,
                isLoading: false,
                list: payload.data,
                totals: _.size(payload.data),
                type: payload.type
            };
        case FETCH_ROLE:
            return {
                ...state,
                active: {
                    isLoading: true,
                    role: {}
                }
            };
        case GET_ROLE:
            return {
                ...state,
                active: {
                    isLoading: false,
                    role: payload.data
                }
            };
        case FETCH_PERMISSIONS:
            return {
                ...state,
                permission: {
                    isLoading: true,
                    list: []
                }
            };
        case LIST_PERMISSIONS:
            return {
                ...state,
                permission: {
                    isLoading: false,
                    list: payload.data
                }
            };
        case FILTER_PERMISSIONS:
            return {
                ...state,
                permission: {
                    list: payload.data,
                    filtered: payload.filterPermissions,
                    module: payload.module,
                }
            };
        default:
            return state;
    }
}