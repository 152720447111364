import {FETCH_OTHER, LIST_OTHER} from "./types";

const init = {
    isLoading: true,
    list: [],
    page: 1,
    totals: 0,
    size: 10
};

export default (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_OTHER:
            return {
                ...state,
                isLoading: true
            };
        case LIST_OTHER:
            return {
                ...state,
                isLoading: false,
                list: payload.data,
                ...payload.paging
            };
        default:
            return state;
    }
}